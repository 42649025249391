import { Box,Button, CssBaseline, Grid, Paper, Stack, TextField, AppBar, Typography,useMediaQuery,useTheme } from "@mui/material"
import { useState, useEffect } from "react"
import QRCode from "qrcode.react"
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import {analytics} from "./firebase"
import {Helmet} from "react-helmet"

function App() {
  const [name, setName] = useState("");
  const [family, setFamily] = useState("");
  const [email, setEMail] = useState("");
  const [tel, setTel] = useState("");
  const [adr, setAdr] = useState("");
  const [birth, setBirth] = useState("");
  const [qrcode, setQrcode] = useState("");
  const isMobile = useMediaQuery(useTheme().breakpoints.down("md"));

  useEffect(() => {
    let vcardText = "BEGIN:VCARD\n";
    vcardText += "VERSION:3.0\n";
    vcardText += `N:${family};${name}\n`;
    vcardText += `EMAIL;TYPE=INTERNET;TYPE=PREF:${email}\n`;
    vcardText += `TEL;TYPE=PREF:${tel}\n`;
    vcardText += `ADR;TYPE=PREF:${adr}\n`;
    vcardText += `BDAY:${birth}\n`;
    vcardText += `END:VCARD\n`;
    setQrcode(vcardText);
  }, [qrcode, name, family, email, tel, adr, birth]);

  const onSaveClick = () => {
    const qrcodeCanvas = document.getElementById("qrcode");
    if (!qrcodeCanvas) {
      return false;
    }
    qrcodeCanvas.toBlob((blob) => {
      const qrcodeUrl = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.download = "qrcode.png";
      a.href = qrcodeUrl;
      a.click();
      a.remove();
    }, "image/png");
  };

  return (
    <div className="App">
      <CssBaseline />
      <Helmet>
        <meta name="description" content="QR名刺とは読み込むだけでスマホの連絡先に登録するQRコードのことです。必要項目を入力すれば誰でも簡単に連絡先に登録できるQRコードを作成することができます。QR名刺の作成では個人情報の収集を行っておりませんので、誰でも安心してご利用いただけます。QRコードの下にある保存ボタンを押すと画像ファイルとして保存できますので、名刺や広告に印刷してご利用いただけます。" />
      </Helmet>
      <AppBar position="static">
        <Typography variant="h4" component="div" sx={{ flexGrow: 1, margin: 1 }}>
          QR名刺
        </Typography>
      </AppBar>
      <Box sx={{
        maxWidth: "1440px",
        margin: "8px auto"
      }}>
        <Paper style={{ padding: "8px" }}>
          <h2>QR名刺ってなに？</h2>
          <div>
            <p>QR名刺とは読み込むだけでスマホの連絡先に登録するQRコードのことです。</p>
            <p>必要項目を入力すれば誰でも簡単に連絡先に登録できるQRコードを作成することができます。</p>
            <p>QR名刺の作成では個人情報の収集を行っておりませんので、誰でも安心してご利用いただけます。</p>
            <p>QRコードの下にある保存ボタンを押すと画像ファイルとして保存できますので、名刺や広告に印刷してご利用いただけます。</p>
          </div>
        </Paper>
      </Box>
      <Box sx={{
        maxWidth: "1440px",
        margin: "8px auto"
      }}>
        <Paper style={{ padding: "8px" }}>
          <h2>QR名刺作成フォーム</h2>
          <Grid container spacing={1}>
            <Grid item xs={12} md={2}>
              <p>名前</p>
            </Grid>
            <Grid item xs={12} md={10}>
              <Stack direction="row" spacing={2}>
                <TextField autoComplete="family-name" variant="outlined" label="姓" style={{ width: "50%" }} value={family} onChange={(e) => { setFamily(e.target.value); }} />
                <TextField autoComplete="given-name" variant="outlined" label="名" style={{ width: "50%" }} value={name} onChange={(e) => { setName(e.target.value); }} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <p>メールアドレス</p>
            </Grid>
            <Grid item xs={12} md={10}>
              <Stack spacing={1}>
                <TextField autoComplete="email" variant="outlined" type="email" label="メールアドレス" fullWidth value={email} onChange={(e) => { setEMail(e.target.value); }} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <p>TEL</p>
            </Grid>
            <Grid item xs={12} md={10}>
              <Stack spacing={1}>
                <TextField variant="outlined" label="TEL" autoComplete="tel" type="tel" fullWidth value={tel} onChange={(e) => { setTel(e.target.value); }} />
              </Stack>
            </Grid>
            <Grid item xs={12} md={2}>
              <p>住所</p>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField variant="outlined" label="住所" fullWidth value={adr} onChange={(e) => { setAdr(e.target.value); }} />
            </Grid>
            <Grid item xs={12} md={2}>
              <p>誕生日</p>
            </Grid>
            <Grid item xs={12} md={10}>
              <TextField variant="outlined" type="date" fullWidth value={birth} onChange={(e) => { setBirth(e.target.value); }} />
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ textAlign: "center", margin: "8px 0px" }}>
                <Box>
                  <QRCode size={isMobile ? "64":"256"} value={qrcode} id="qrcode" />
                </Box>
                <Box>
                  <Button startIcon={<SaveAltIcon />} onClick={onSaveClick}>保存</Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Box>
      <Box component="footer" sx={{ bgcolor: 'primary.main', color: 'primary.contrastText',padding:"5px 0px" }}>
        <p style={{textAlign:"center"}}>Copyright © qr-meishi.com All Rights Reserved.</p>
      </Box>
    </div>
  );
}

export default App;
